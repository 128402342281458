import React from "react";

import { cn } from "@/lib/utils";

type Props = {
  className?: string;
  label: React.ReactNode;
};

function Description({ label = "Need Description", className }: Props) {
  return (
    <p className={cn(className, "text-base leading-7 font-normal text-[#000]")}>
      {label}
    </p>
  );
}

export default Description;
