/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from 'antd';
import { Trans, useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import TagManager from 'react-gtm-module';

import { MediaQuery } from '#widgets/media-query';
import Highlight from '#widgets/reatc-element/highlight';
// eslint-disable-next-line import/order
import Container from '#widgets/reatc-element/style/components/container';

// "home.title": "Hire talents you <1>literally</1> cannot find elsewhere!",
//   "home.description": "WINaTALENT is your source for <1>exceptional talent</1> at <2>competitive</2> \n<3>rates</3>.\nStartups trust our talent pool for their software development,\ndesign, product and marketing challenges.\nWhat sets us apart is our talent pool's <4>problem-solving</4> abilities,\n<5>seniority</5>, and <6>competitive rates</6>.",

const ImageMobile = () => (
  <>
    <div className=" absolute  z-0 h-full w-screen  md:hidden">
      <Image
        priority
        loading="eager"
        fill
        src="/images/home-page/hero/hero-mobile.png"
        alt=""
      />
    </div>
    <div className=" absolute right-0 hidden h-full w-[700px] md:flex">
      <Image
        fill
        src="/images/home-page/hero/hero.png"
        alt="WINaTALENT"
        priority
        loading="eager"
      />
    </div>
  </>
);

const DescriptionHeader = ({ description }: { description: string }) => (
  <>
    <div
      className="z-10
      whitespace-pre-line
        text-lg
        font-normal
        text-[#EEE]
        md:w-[700px]
        md:text-2xl
        md:font-medium
        md:text-black"
    >
      <Trans
        className="
        text-start
          text-lg
          font-normal
          leading-7
         md:text-2xl
          md:font-medium"
        i18nKey={description}
        components={{
          H: <Highlight className="inline-block" width="full" />,
        }}
      />
    </div>
  </>
);

type Props = {
  title: string;
  description: string;
};

const Hero: FC<Props> = ({ description, title }) => {
  const { t } = useTranslation();
  return (
    <section className="relative flex h-max overflow-hidden !bg-opacity-25 md:bg-[#86A5AF] md:pb-0  ">
      <Container
        tag="div"
        className="relative z-10 mx-auto   flex flex-col items-center   gap-10  px-2 md:mt-20 md:items-start "
      >
        <h1
          className="
          z-10
          mb-0
        mt-10
        text-5xl
        font-bold
        leading-[60px]
        text-[#F8F8F8]
        md:mb-7
md:mt-0
        md:w-[660px]
        md:text-[56px]
        md:text-[#333333]
        "
        >
          {title}
        </h1>
        <DescriptionHeader description={description} />
        <Link
          onClick={() => {
            TagManager.dataLayer({
              dataLayer: {
                event: 'hero_CTA_click',
              },
            });
            return null;
          }}
          id="hero_win_talent"
          href={{
            pathname: '/quiz',
            query: {
              showQuiz: true,
            },
          }}
          target="_blank"
          className="z-10"
        >
          <Button className="btn-primary mb-10 h-[72px] w-[280px] text-2xl font-bold text-white md:mb-[104px] ">
            Start Hiring!
          </Button>
        </Link>
        <ImageMobile />
      </Container>
    </section>
  );
};
export default Hero;
