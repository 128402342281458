/* eslint-disable react/button-has-type */
/* eslint-disable max-len */
import React, { useState } from 'react';

import { Flex } from '#widgets';

import styles from './style.module.less';

const AboutWINaTALENTComponent = ({ data }) => {
  const [isShowMotrBtn, setIsShowMotrBtn] = useState(true);
  const aboutData = {
    title: 'What is WINaTALENT ?'
    // description:  `<p>
    // We started WINaTALENT in 2019 in Estonia to connect talented individuals from Western Asia and Eastern Europe to top companies and employers. </p>\n
    // <p>There were always various talent platforms that did the same and connected freelancers to employers as online freelance websites. What made us enter this market despite how competitive it is was that we realized Western Asian and Eastern Europe IT experts were highly skilled. Yet political and regional issues forbid them from working with international companies.</p>\n
    // <p>
    // With our experience in HR and talent acquisition and by analyzing numerous employers,
    // we learned that the companies that had managed to work with talents from these countries were happy to work with them. They specifically mentioned that these talents delivered a high-quality product withhighly competitive rates. By relying on this value, we started negotiating with already existing national businesses in the talent industry so we could make this connection with their partnership. What we want to create at WINaTALENT is to provide each talented individual with the opportunity to work in the international market with professional employers. WINaTALENT has launched its online talent platform to achieve this goal.
    // </p>`
  };

  return (
    <Flex className={styles.container} direction="column" directionlg="column" items="center">
      <strong>{aboutData.title}</strong>
      {isShowMotrBtn
        ? (
          <>
            {/* <p
              dangerouslySetInnerHTML={{
                __html: `<p>
                We started WINaTALENT in 2019 in Estonia to connect talented individuals from Western Asia and Eastern Europe to top companies and employers. </p>\n
                <p>There were always various talent platforms that did the same and connected freelancers to employers as online freelance websites. What made us enter this market despite how competitive it is was that we realized Western Asian and Eastern Europe IT experts were highly skilled. Yet political and regional issues forbid them from working with international companies.</p>\n
                <p>
                With our experience in HR and talent acquisition and by analyzing numerous employers,
                we learned that the companies that had managed to work with talents from these countries were happy to work with them. They specifically mentioned that these talents delivered a high-quality product withhighly competitive rates. By relying on this value, we started negotiating with already existing national businesses in the talent industry so we could make this connection with their partnership. What we want to create at WINaTALENT is to provide each talented individual with the opportunity to work in the international market with professional employers. WINaTALENT has launched its online talent platform to achieve this goal.
                </p>` }}
              className={styles.fadeOn}
            ></p> */}
            <p className={styles.fadeOn}>
              We started WINaTALENT in 2019 in Estonia to connect talented individuals from Western Asia and Eastern Europe to top companies and employers.<br />
              There were always various talent platforms that did the same and connected freelancers to employers as online freelance websites. What made us enter this market despite how competitive it is was that we realized Western Asian and Eastern Europe IT experts were highly skilled. Yet political and regional issues forbid them from working with international companies.<br />
              With our experience in HR and talent acquisition and by analyzing numerous employers,
              we learned that the companies that had managed to work with talents from these countries were happy to work with them. They specifically mentioned that these talents delivered a high-quality product withhighly competitive rates. By relying on this value, we started negotiating with already existing national businesses in the talent industry so we could make this connection with their partnership. What we want to create at WINaTALENT is to provide each talented individual with the opportunity to work in the international market with professional employers. WINaTALENT has launched its online talent platform to achieve this goal.
            </p>
            <button onClick={() => {
              setIsShowMotrBtn(false);
            }}
            >
              Show More
            </button>
          </>
        )
        : (
          <>
            {/* <p
              dangerouslySetInnerHTML={{
                __html: `<p>
                We started WINaTALENT in 2019 in Estonia to connect talented individuals from Western Asia and Eastern Europe to top companies and employers. </p>\n
                <p>There were always various talent platforms that did the same and connected freelancers to employers as online freelance websites. What made us enter this market despite how competitive it is was that we realized Western Asian and Eastern Europe IT experts were highly skilled. Yet political and regional issues forbid them from working with international companies.</p>\n
                <p>
                With our experience in HR and talent acquisition and by analyzing numerous employers,
                we learned that the companies that had managed to work with talents from these countries were happy to work with them. They specifically mentioned that these talents delivered a high-quality product withhighly competitive rates. By relying on this value, we started negotiating with already existing national businesses in the talent industry so we could make this connection with their partnership. What we want to create at WINaTALENT is to provide each talented individual with the opportunity to work in the international market with professional employers. WINaTALENT has launched its online talent platform to achieve this goal.
                </p>` }}
              className={styles.fadeOff}
            ></p> */}
            <p className={styles.fadeOff}>
              We started WINaTALENT in 2019 in Estonia to connect talented individuals from Western Asia and Eastern Europe to top companies and employers.<br />
              There were always various talent platforms that did the same and connected freelancers to employers as online freelance websites. What made us enter this market despite how competitive it is was that we realized Western Asian and Eastern Europe IT experts were highly skilled. Yet political and regional issues forbid them from working with international companies.<br />
              With our experience in HR and talent acquisition and by analyzing numerous employers,
              we learned that the companies that had managed to work with talents from these countries were happy to work with them. They specifically mentioned that these talents delivered a high-quality product withhighly competitive rates. By relying on this value, we started negotiating with already existing national businesses in the talent industry so we could make this connection with their partnership. What we want to create at WINaTALENT is to provide each talented individual with the opportunity to work in the international market with professional employers. WINaTALENT has launched its online talent platform to achieve this goal.
            </p>
          </>
        )}

    </Flex>
  );
};

export default AboutWINaTALENTComponent;
