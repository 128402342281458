import { SquareArrowOutUpRight } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import { Container } from "@/app/_reatc-element";
import { inter } from "@/app/font";
import imageByo from "@/assets/home-page/BYO Cover-min.jpg";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

import Description from "../components/description";
import Subtitle from "../components/subtitle";
import Title from "../components/title";

export default function Byo() {
  return (
    <section className="bg-gray-100">
      <Container
        className={cn(
          "flex flex-col-reverse items-center gap-4 py-16 md:gap-8 lg:flex-row ",
          inter.className,
        )}
      >
        <div className="flex flex-col md:w-1/2 md:py-2">
          <div className="flex items-stretch gap-2">
            <Title label="BYO" />
          </div>

          <div className="flex flex-col gap-4">
            <Subtitle label="Bring Your Own Talents" />

            <Separator />

            <div className="hidden flex-col pr-4 md:flex ">
              <Description label=" Want to work with talent you found outside WINaTALENT?" />
              <Description label="Our bring-your-own feature makes it easy to manage your" />
              <Description label=" remote talents, from contract to payment, wherever they are." />
            </div>
            <div className="flex flex-col pr-4 md:hidden ">
              {/* <Description label=" Want to work with talent you found outside WINaTALENT?" />
              <Description label="Our bring-your-own feature makes it easy " />
              <Description label="to manage your remote talents, from contract to payment, " />
              <Description label=" wherever they are." /> */}
              <Description label="Want to work with talent you found outside WINaTALENT? Our bring-your-own feature makes it easy to manage your remote talents, from contract to payment, wherever they are." />
            </div>
            <div className="flex flex-col items-center gap-6 md:flex-row ">
              <Button
                className="h-[54px] w-full gap-[10px] text-lg font-semibold md:w-[183px]"
                variant={"primary"}
                asChild
              >
                <Link href={"/byo"} target="_blank">
                  Learn more
                  <SquareArrowOutUpRight size={"24"} />
                </Link>
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-auto md:w-1/2">
          {/* <Image src={refriendsImage} width={464} height={275} alt="refredns " /> */}
          <Image
            src={imageByo}
            width={464}
            height={275}
            alt="refredns "
            className="h-[193px] w-full object-contain sm:w-auto md:h-[275px] md:w-full "
          />{" "}
        </div>
      </Container>
    </section>
  );
}
