import { SquareArrowOutUpRight } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import { Container } from "@/app/_reatc-element";
import { inter } from "@/app/font";
import refriendsImage from "@/assets/home-page/Refriend Cover-min.jpg";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

import Description from "../components/description";
import Subtitle from "../components/subtitle";
import Title from "../components/title";

export default function Refriends() {
  return (
    <Container
      className={cn(
        "flex flex-col items-center gap-4 py-16 md:gap-8 lg:flex-row ",
        inter.className,
      )}
    >
      <div className="md:w-1/2">
        {/* <Image src={refriendsImage} width={464} height={275} alt="refredns " /> */}
        <Image
          src={refriendsImage}
          width={464}
          height={275}
          alt="refredns "
          className="h-[200px] w-full sm:w-[464px] md:h-[275px] md:w-full object-contain"
        />{" "}
      </div>
      <div className="flex w-full flex-col md:w-1/2 md:py-2">
        <div className="flex items-stretch gap-2">
          <Title label="Refriend" />
          <Badge variant={"destructive"} className="rounded font-bold">
            New
          </Badge>
        </div>

        <div className="flex flex-col gap-4 ">
          <Subtitle label="Global Careers, Trusted Referrers" />

          <Separator />
          <div className="hidden flex-col md:flex ">
            <Description label="Refriend connects job seekers to global visa-sponsored" />
            <Description label="jobs and offers a referrer for recommendations," />
            <Description label="streamlining the search for diverse opportunities." />
          </div>
          <div className="flex flex-col md:hidden ">
            <Description label="Refriend connects job seekers to global visa-" />
            <Description label="sponsored jobs and offers a referrer for" />
            <Description label="recommendations, streamlining the search for" />
            <Description label="diverse opportunities." />
          </div>
          <div className="flex flex-col items-center gap-6 md:flex-row ">
            <Button
              className="h-[54px] w-full gap-[10px] text-lg font-semibold md:w-[183px]"
              variant={"primary"}
              asChild
            >
              <Link href={"https://refriend.winatalent.com"} target="_blank">
                Learn more
                <SquareArrowOutUpRight size={"24"} />
              </Link>
            </Button>
            <Button
              variant={"link"}
              asChild
              className=" flex items-stretch gap-2 text-lg font-normal text-neutral-950"
            >
              <Link target="_blank" href={"/refriend"}>
                View Refiend Position list <SquareArrowOutUpRight size={"24"} />
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}
