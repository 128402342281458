/* eslint-disable react/prop-types */
import dynamic from "next/dynamic";
import React, { FC, memo } from "react";

import { ILandingPage } from "#types/landing";
import { RenderOnViewportEntry } from "#widgets";

import AboutWINTaTALENT from "../sharedComponents/AboutWINaTALENT/AboutWINTaTALENT";
import Hero from "./header";
import Refriends from "./refriends";
import Byo from "./byo";

const WinServices = dynamic(
  () => import("#widgets/common/landing/win_services"),
);
const WhyWin = dynamic(() =>
  import("#widgets/common/landing/why-winatalent").then(
    (module) => module.WhyWin,
  ),
);
const Talents = dynamic(() =>
  import("#widgets/common/landing/talent").then((module) => module.Talents),
);
const HowToHire = dynamic(() => import("#widgets/common/landing/how_to_hire"));
const ClientTestimonial = dynamic(
  () => import("#widgets/common/landing/client-testimonial"),
);
const LandingFooter = dynamic(() =>
  import("#widgets/common/landing/footer").then(
    (module) => module.LandingFooter,
  ),
);
// type Props = {
//   // eslint-disable-next-line no-undef
//   clientsTestimonials: TypeTtestimonial[];
//   landingData: ILandingPage;
//   imageUrl: string;
// };

const Home: FC<ILandingPage> = ({
  testimonials,
  main_content,
  title,
  top_description,
}) => (
  <main className="flex flex-col">
    <Hero description={top_description} title={title} />
    <RenderOnViewportEntry>
      <WinServices
        bgColor="bg-[#F2F2F2]"
        bgCard="/images/WIN-services-bg.svg"
        colorTitle="text-[#28ABA7]"
        colorTitleCard="text-[#28ABA7]"
        colorDescriptionCard="text-[#4D5B71]"
        colorIconCard="#4D5B71"
        colordividerCard="border-[#4D5B71]"
      />
    </RenderOnViewportEntry>
    <RenderOnViewportEntry>
      <WhyWin />
    </RenderOnViewportEntry>

    <RenderOnViewportEntry>
      <Talents
        id="our-talents"
        mainContent={main_content || []}
        title="Some of Our Talents"
      />
    </RenderOnViewportEntry>
    <RenderOnViewportEntry>
      <Refriends />
    </RenderOnViewportEntry>
    <RenderOnViewportEntry>
      <Byo />
    </RenderOnViewportEntry>
    <RenderOnViewportEntry>
      <HowToHire id="how_it_works" title="" />
    </RenderOnViewportEntry>
    {/* <LandingOurClients data={clientsTestimonials} bgColor="bg-white" /> */}
    <RenderOnViewportEntry>
      <ClientTestimonial
        id="testimonials"
        data={testimonials}
        bgColor="bg-white"
      />
    </RenderOnViewportEntry>
    <RenderOnViewportEntry>
      <LandingFooter />
    </RenderOnViewportEntry>
    <RenderOnViewportEntry>
      <AboutWINTaTALENT data={undefined} />
    </RenderOnViewportEntry>
  </main>
);

export default memo(Home);
