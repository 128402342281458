import React from "react";

import { cn } from "@/lib/utils";

type Props = {
  className?: string;
  label?: React.ReactNode;
};

export default function Title({ className, label = "Need Label" }: Props) {
  return (
    <h1 className={cn(" m-0 text-lg font-bold text-[#000000]", className)}>
      {label}
    </h1>
  );
}
