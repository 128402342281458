import React from "react";

import { cn } from "@/lib/utils";

type Props = {
  className?: string;
  label?: React.ReactNode;
};

export default function Subtitle({
  className,
  label = "Need Subtitle",
}: Props) {
  return (
    <p className={cn(className, "text-lg font-normal text-[#000] ")}>{label}</p>
  );
}
